.App {
  width: 100%;
  text-align: center;
}

.posts {
  width: 50%;
  float: left;
}

.newPosts {
  float: right;
  width: 50%;
}
.postForm {
  float: right;
  margin-right: 10%;
}

.textBox {
  height: 100px;
  resize: none;
}
